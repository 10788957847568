<template>
    <div class="orderCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            label-width="80px"
            label-position="right"
            :model="form"
            ref="createForm"
            size="small"
            :rules="rules"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item prop="type" label="批发类型">
                            <el-radio v-model="form.type" :label="0">采购订单</el-radio>
                            <el-radio v-model="form.type" :label="1">单方销售</el-radio>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="销售机构" prop="repoCode">
                            <el-select
                                v-model="form.repoCode"
                                ref="repoDeptSelect"
                                @change="changeRepo"
                                filterable
                                style="width: 196px"
                            >
                                <el-option
                                    v-for="item in repoDepts"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input readonly :value="createTime" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="creator" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="客户" prop="customer">
                            <el-input v-model.trim="form.customer" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户电话" prop="customerMobile">
                            <el-input v-model.trim="form.customerMobile" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户地址" prop="customerAddress">
                            <el-input v-model.trim="form.customerAddress" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item key="deptCode" v-if="showPurchaseStoreDeptFlag" prop="deptCode" label="进货机构">
                            <el-select placeholder="请选择" v-model="form.deptCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="storeDept in storeDepts"
                                    :label="storeDept.name"
                                    :value="storeDept.code"
                                    :key="storeDept.code"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item key="deptName" v-else label="进货机构" prop="deptName">
                            <el-input v-model.trim="form.deptName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input placeholder="商品名称/条码/助记码/自编码" v-model.trim="search" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" />
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" @click="queryAndAdd" size="small" :disabled="disableQueryAndAddButton"
                    >查询并添加
                </el-button>
                <el-button
                    type="primary"
                    @click="handleSelectGoods"
                    size="small"
                    :disabled="disableShowPickUpGoodsDialog"
                    >选择商品基本信息
                </el-button>
                <el-button type="primary" @click="handleSave" size="small" :disabled="disableSaveButton"
                    >保存</el-button
                >
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    show-summary
                    size="mini"
                    :highlight-current-row="true"
                    max-height="500"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品名称" width="180" fixed="left">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="130">
                        <template slot-scope="scope">
                            <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                        </template>
                    </el-table-column>
                    <el-table-column label="当前库存" width="100">
                        <template slot-scope="scope">
                            {{ goodsStock(scope.row.goodsCode) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="预计可出库库存" :render-header="rendHeader" width="150">
                        <template slot-scope="scope">
                            {{ goodsCanOutStock(scope.row.goodsCode) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="批发价" width="140" prop="showPrices">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showPrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input
                                    v-model="scope.row.showPrices"
                                    :precision="2"
                                    @change="computeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <template v-if="hasBigUnitPrivilege">
                        <el-table-column label="包装因子" prop="packFactors">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.packFactors'"
                                    :rules="rules.packFactors"
                                >
                                    {{ scope.row.packFactors }}
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="采购箱数" min-width="120" prop="bigUnitCounts">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bigUnitCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bigUnitCounts"
                                        @change="computeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="散装数量" min-width="120" prop="bulkCounts">
                            <template slot-scope="scope">
                                <el-form-item
                                    :prop="'detailParams.' + scope.$index + '.bulkCounts'"
                                    :rules="rules.bigUnitCounts"
                                >
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.bulkCounts"
                                        @change="computeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column label="批发数量" width="120" prop="counts">
                        <template slot-scope="scope">
                            <template v-if="hasBigUnitPrivilege">
                                <ef-stock-biz-count-input v-model="scope.row.counts" :disabled="true" />
                            </template>
                            <template v-else>
                                <el-form-item :prop="'detailParams.' + scope.$index + '.counts'" :rules="rules.counts">
                                    <ef-stock-biz-count-input
                                        v-model="scope.row.counts"
                                        @change="computeCountAndMoney(scope.row)"
                                    />
                                </el-form-item>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="批发金额" width="90" prop="saleShowMoney" />
                    <el-table-column label="赠品数量" width="120" prop="giftCounts">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.giftCounts'" :rules="rules.counts">
                                <ef-stock-biz-count-input
                                    v-model="scope.row.giftCounts"
                                    @change="computeCountAndMoney(scope.row)"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="总计数量" width="120" prop="totalCounts">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.totalCounts'"
                                :rules="rules.totalCounts"
                            >
                                <ef-stock-biz-count-input v-model="scope.row.totalCounts" :disabled="true" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="80" header-align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)"> 删除 </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <pickUpGoods ref="pickUpGoods" :selfCanClose="false" @queryGoods="setSelectedGoods" />
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';
import EfRemark from 'components/EfRemark';
import MoneyUtils from 'js/MoneyUtils';
import { DeptTypeEnum } from 'js/DeptCommon';
import EfStockBizCountInput from 'components/EfStockBizCountInput';
import EfPriceInput from 'components/EfPriceInput';
import {
    deptCodeValidateRule,
    goodsCodeValidateRule,
    packFactorsValidateRule,
    pricesValidateRule,
    remarkNotRequiredValidateRule,
} from 'js/validate/ValidateCommonRule';
import { countGreaterThanZeroValidateRule, countValidateRule } from 'js/validate/ValidateStockBizRule';
import StockBizCommon, { packFactor, PropertyConverter, wholeSalePrice } from 'js/StockBizCommon';

const arrChangeMap = (arr) => new Map(arr.map((key, value) => [key.goodsCode, key]));

export default {
    name: 'OrderCreate',
    components: { PickUpGoods, EfRemark, EfStockBizCountInput, EfPriceInput },
    data() {
        return {
            createTime: Util.nowFormatDate(),
            creator: this.$store.state.session.name,
            search: '',
            repoName: '',

            form: {
                deptName: '',
                deptCode: '',
                type: 0,
                repoCode: '',
                remark: '',
                customer: '',
                customerMobile: '',
                customerAddress: '',
                detailParams: [],
            },
            //所选商品详情列表
            goodsDetailMap: new Map(),
            //所选商品库存情况列表
            goodsStockMap: new Map(),
            url: {
                save: '/trade/wholesale/order/createExtend',
            },
            hasBigUnitPrivilege: false,
            repoDepts: [],
            storeDepts: [],
            showPurchaseStoreDeptFlag: false,
            rules: {
                type: {
                    required: true,
                    message: '批发类型必选',
                    trigger: ['blur', 'change'],
                },
                repoCode: deptCodeValidateRule(),
                deptCode: {
                    required: true,
                    message: '请选择进货机构',
                    trigger: ['blur'],
                },
                deptName: {
                    required: false,
                    pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,30}$/,
                    message: '至少两个中文汉字或字母',
                    trigger: ['blur'],
                },
                customer: {
                    required: false,
                    pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,10}$/,
                    message: '至少两个中文汉字或字母',
                    trigger: ['blur'],
                },
                // customerMobile: {required: false, ...mobileValidateRule()},
                customerAddress: {
                    required: false,
                    pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
                    message: '请输入字母汉字或者数字',
                    trigger: ['blur'],
                },
                remark: remarkNotRequiredValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                packFactors: packFactorsValidateRule(),
                showPrices: pricesValidateRule(),
                counts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
                bigUnitCounts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
                totalCounts: [countGreaterThanZeroValidateRule()],
            },
        };
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.REPOSITORY).then((rst) => {
            this.repoDepts = rst;
        });
        this.$efApi.deptApi.companyAllStores().then((rst) => {
            this.storeDepts = rst;
        });
    },
    watch: {
        'form.repoCode': function (newDeptCode, oldDeptCode) {
            //清空商品列表数据
            this.form.detailParams = [];
        },
        'form.type': {
            handler(newOrderType, oldOrderType) {
                this.showPurchaseStoreDeptFlag = newOrderType === 0;
                //清空值
                this.form.deptCode = '';
                this.form.deptName = '';
            },
            immediate: true,
        },
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            return Util.isEmpty(this.form.repoCode) || Util.isEmpty(this.search);
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            return Util.isEmpty(this.form.repoCode);
        },
        //禁用/启用按钮
        disableSaveButton() {
            return (
                Util.isEmpty(this.form.repoCode) ||
                this.form.detailParams == null ||
                this.form.detailParams.length === 0
            );
        },
    },
    methods: {
        changeRepo() {
            this.tableData = [];
            this.hasBigUnitPrivilege = false;
            UrlUtils.HasBigUnitPrivilege(this, this.form.repoCode, (rst) => {
                this.hasBigUnitPrivilege = rst;
            });
            //清空商品列表
        },
        detailParamPropertyArrForAdd(enabledBigUnit) {
            const rst = [
                'goodsCode',
                new PropertyConverter(null, 'giftCounts', (giftCounts) => {
                    //赠品初始默认数量为0
                    return 0;
                }),
                new PropertyConverter(null, 'counts'),
                new PropertyConverter(wholeSalePrice, 'showPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
                //商品库存
                'stock',
                //预计可出库库存
                'canOutStock',
            ];
            if (enabledBigUnit) {
                rst.push(
                    new PropertyConverter(null, 'bigUnitCounts'),
                    new PropertyConverter(null, 'bulkCounts'),
                    new PropertyConverter(packFactor, 'packFactors', (packFactor) => {
                        return Util.isEmpty(packFactor) ? 1 : packFactor;
                    })
                );
            }
            return rst;
        },
        computeCountAndMoney(row) {
            this.$nextTick(() => {
                //计算数量和金额
                let counts;
                if (this.hasBigUnitPrivilege) {
                    //启用大单位
                    let bulkCounts = row.bulkCounts || 0;
                    const packFactors = row.packFactors || 1;
                    let bigUnitCounts = row.bigUnitCounts || 0;
                    const bigUnitCountsForBulkCounts = Math.floor(bulkCounts / packFactors);
                    if (bigUnitCountsForBulkCounts > 0) {
                        bigUnitCounts = this.decimal(bigUnitCounts).add(bigUnitCountsForBulkCounts).toNumber();
                        bulkCounts = bulkCounts % packFactors;
                        row.bulkCounts = bulkCounts;
                        row.bigUnitCounts = bigUnitCounts;
                    }
                    counts = this.decimal(packFactors).mul(bigUnitCounts).add(bulkCounts).toNumber();
                } else {
                    counts = row.counts || 0;
                }
                row.counts = counts;
                row.saleShowMoney = this.decimal(counts || 0)
                    .mul(row.showPrices || 0)
                    .toNumber();
                //计算总数量
                row.totalCounts = this.decimal(counts)
                    .add(row.giftCounts || 0)
                    .toNumber();
            });
        },
        handleSelectGoods() {
            const repoDeptName = this.$refs.repoDeptSelect.selected.label;
            this.$refs.pickUpGoods.show(this.pickUpGoodsQueryParam(), repoDeptName);
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ ...this.pickUpGoodsQueryParam(), search: this.search });
            this.setSelectedGoods(rst.data);
        },
        pickUpGoodsQueryParam() {
            return {
                deptCode: this.form.repoCode,
                goodsStatusIn: [1],
                page: 1,
                limit: 100,
            };
        },
        handleDelete(index) {
            this.form.detailParams.splice(index, 1);
        },
        async setSelectedGoods(selectedGoods) {
            const oldDetails = Object.assign([], this.form.detailParams || []);
            await StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                oldDetails,
                this.detailParamPropertyArrForAdd(this.hasBigUnitPrivilege)
            );
            if (oldDetails.length <= 150) {
                const goodsStocks =
                    (await this.$efApi.goodsStock.queryAllCanOutStocks([...this.goodsDetailMap.keys()])) ?? [];
                this.goodsStockMap = arrChangeMap(goodsStocks);
                this.form.detailParams = oldDetails;
            } else {
                this.$message.warning('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
            }
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        goodsStock(goodsCode) {
            return this.goodsStockMap.has(goodsCode) ? this.goodsStockMap.get(goodsCode).stock ?? '--' : '--';
        },
        goodsCanOutStock(goodsCode) {
            return this.goodsStockMap.has(goodsCode) ? this.goodsStockMap.get(goodsCode).canOutStock ?? '--' : '--';
        },
        handleSave() {
            this.$refs.createForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                const form = this.form;
                const detailParams = form.detailParams;
                if (detailParams.length > 150) {
                    this.$message.error('每笔单据做多添加150个商品，如想添加更多商品，请拆分为多条单据');
                    return false;
                }
                const params = {
                    repoCode: form.repoCode,
                    deptCode: form.deptCode,
                    deptName: form.deptName,
                    customer: form.customer,
                    customerMobile: form.customerMobile,
                    customerAddress: form.customerAddress,
                    remark: form.remark,
                    type: form.type,

                    counts: detailParams.map((r) => r.counts),
                    goodsCode: detailParams.map((r) => r.goodsCode),
                    giftCounts: detailParams.map((r) => r.giftCounts),
                    packFactors: detailParams.map((r) => r.packFactors),
                    prices: detailParams.map((r) => MoneyUtils.moneyToDb(r.showPrices)),
                };
                UrlUtils.PostRemote(
                    this,
                    this.url.save,
                    params,
                    null,
                    () => {
                        this.goBackAndReload();
                        this.$message.success('保存成功');
                    },
                    (rst) => {
                        this.$message.error(rst);
                    }
                );
            });
        },
        rendHeader(h, { column }) {
            return h(
                'div',
                {
                    style: 'display:flex;',
                },
                [
                    h('span', column.label),
                    h('prompt-message', {
                        props: {
                            messages: [
                                '预计可出库库存=商品库存-已审核待出库单据中该商品的数量，出现负数表示已审核单据中该商品预计出库的数量超过了商品实际库存。预计可出库库存不代表实际可出库的库存。',
                            ],
                        },
                    }),
                ]
            );
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}

.el-input-number {
    width: 100%;
    height: 32px;
}
</style>
